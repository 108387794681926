<template>
  <div class="operations">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <h1 class="subheading grey--text mt-4">Список авто</h1>
    <v-dialog v-model="show" width="auto" max-width="600">
      <v-card>
        <v-card-title>{{ selectedCar.title }}</v-card-title>
        <v-textarea
          class="px-5"
          label="Текст для менеджера"
          v-model="selectedCar.managerText"
          type="text"
        >
          <!-- <template slot="append">
            <v-btn block color="primary" small @click="saveCar('managerText')"
              >💾</v-btn
            >
          </template> -->
        </v-textarea>

        <v-card-actions>
          <v-btn @click="show = false">Назад</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="saveCar('managerText')"
            >💾</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      dense
      :headers="headerss"
      :items="myCars"
      :items-per-page="10"
      :search="totalsTable.search"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      class="elevation-1"
      :single-expand="enableSingleExpand"
      mobile-breakpoint="0"
    >
      <template v-for="h in headerss" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip top :key="h + header">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text }}</span>
          </template>
          <span>{{ h.hint ? h.hint : h.text }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.change="{ item }">
        <v-btn variant="outlined" dense x-small @click="handleSettings(item)">
          ⚙️
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      show: false,
      enableSingleExpand: true,
      totalsTable: {
        search: '',
        headers: [
          {
            text: 'Автомобиль',
            align: 'start',
            sortable: false,
            value: 'title',
          },
          // { text: 'Доля', value: 'chunk' },
          // { text: 'Ваш доход', value: 'ownerProfit' },
          // { text: 'Выручка', value: 'proceeds' },
          // { text: 'Расходы', value: 'consumption' },
          // { text: 'Комиссия УК', value: 'comission' },
          { text: 'Текст для менеджера', value: 'managerText', width: '40%' },
          { text: '', value: 'change' },
        ],
      },
    };
  },
  methods: {
    ...mapActions(['updateFieldInCar']),
    async saveCar(fieldName) {
      const patch = await this.updateFieldInCar({
        carId: this.selectedCar.id,
        fieldName: fieldName,
        value: this.selectedCar[fieldName],
      });
      if (patch) await this.getCars();
    },
    handleSettings(x) {
      this.dialog = x.id;
      this.show = true;
      // this.selectedCar = x;
    },
    dateFormat(ISOdate) {
      return moment(ISOdate).format('DD.MM.YYYY');
    },
    minDateTo(type) {
      if (type == 'dateAt') return moment('2019-01-01').format('YYYY-MM-DD');
      if (type == 'dateTo') {
        if (this.dateAt) return moment(this.dateAt).format('YYYY-MM-DD');
        else return moment().format('YYYY-MM-DD');
      }
    },
    saveMonth() {
      if (this.dates.length == 2) {
        this.datesMenu = false;
      }
    },
    async getCars() {
      this.loading = true;
      await this.$store.dispatch('getMyCars');
      this.loading = false;
    },
    sumField(key) {
      // sum data in give key (property)
      if (this.carOperations.cars) {
        const sum = this.carOperations.cars.reduce(
          (a, b) => a + (b[key] || 0),
          0
        );
        return new Intl.NumberFormat('ru-RU').format(sum);
      }
    },
  },
  computed: {
    headerss() {
      const headers = [
        {
          text: 'Автомобиль',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        // { text: 'Доля', value: 'chunk' },
        // { text: 'Ваш доход', value: 'ownerProfit' },
        // { text: 'Выручка', value: 'proceeds' },
        // { text: 'Расходы', value: 'consumption' },
        // { text: 'Комиссия УК', value: 'comission' },
        { text: 'Текст для менеджера', value: 'managerText', width: '40%' },
        { text: '', value: 'change' },
      ];
      if (this.myCars.length) {
        for (let key in this.carHints) {
          headers.find((x) => x.value == key).hint = this.carHints[key];
        }
      }
      return headers;
    },
    showEdit() {
      return !!this.dialog;
    },
    selectedCar() {
      if (this.dialog) {
        return this.myCars.find((x) => x.id == this.dialog);
      }
      return 123;
    },
    datesStr() {
      moment.locale('ru');
      return `${moment(this.dates[0]).format('MMMM')} - ${moment(this.dates[1])
        .endOf('month')
        .format('MMMM')}`;
    },
    responsiveStyleForImages() {
      const widthOfScreen = window.innerWidth;
      let maxW = 600;
      if (widthOfScreen < 650) maxW = window.innerWidth - 50;
      return `max-width:${maxW}px; height: 500px; margin: auto`;
    },
    maxWidth() {
      const widthOfScreen = window.innerWidth;
      let maxW = 800;
      if (widthOfScreen < 650) maxW = window.innerWidth;
      return maxW;
    },
    maxHeight() {
      const heightOfScreen = window.innerHeight;
      let maxH = 800;
      if (heightOfScreen < 650) maxH = window.innerHeight;
      return maxH;
    },
    ...mapGetters(['myCars', 'carHints']),
  },
  async mounted() {
    await this.getCars();
  },
};
</script>

<style lang="scss" scoped>
.textField {
  min-width: 230px;
}
</style>
